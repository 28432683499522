import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HollyDaysCalendarRoutingModule } from './holly-days-calendar-routing.module';
import { HollyDaysCalendarComponent } from './holly-days-calendar/holly-days-calendar.component';
import { PrimeAllModule } from '../../../common/prime-all/prime-all.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouterOutlet } from '@angular/router';
import { HollyDaysCalendarAddUpdComponent } from './holly-days-calendar-add-upd/holly-days-calendar-add-upd.component';
import { HollyDaysCalendarFormComponent } from './holly-days-calendar-form/holly-days-calendar-form.component';
import { HollyCalendarListComponent } from './holly-calendar-list/holly-calendar-list.component';

@NgModule({
	declarations: [
		HollyDaysCalendarComponent,
		HollyDaysCalendarAddUpdComponent,
		HollyDaysCalendarFormComponent,
		HollyCalendarListComponent,
	],
	imports: [
		CommonModule,
		HollyDaysCalendarRoutingModule,
		RouterModule,
		RouterOutlet,
		PrimeAllModule,
		ReactiveFormsModule,
		FormsModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HollyDaysCalendarModule {}
