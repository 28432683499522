import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./gui/landing/landing.module').then((x) => x.LandingModule),
	},
	{
		path: 'users',
		loadChildren: () =>
			import('./business/users-management/users-management.module').then(
				(m) => m.UsersManagementModule
			),
	},
	{
		path: 'entities',
		loadChildren: () =>
			import('./business/entity-setup/entity-setup-routing.module').then(
				(m) => m.EntitySetupRoutingModule
			),
	},
	{
		path: 'datamanager',
		loadChildren: () =>
			import('./business/data-manager/data-manager-routing.module').then(
				(m) => m.DataManagerRoutingModule
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
