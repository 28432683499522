import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, switchMap } from 'rxjs';

export const mainInterceptor: HttpInterceptorFn = (req, next) => {
	const keycloakService = inject(KeycloakService);

	return from(keycloakService.getToken()).pipe(
		switchMap((token) => {
			const headers = req.headers.set('Authorization', 'Bearer ' + token);
			const requestClone = req.clone({
				headers,
			});

			return next(requestClone);
		})
	);

	//return next(req);
};
