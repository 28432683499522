import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SSOInitializer } from './utils/sso/sso-app-init';
import { RouterModule, RouterOutlet } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { UpperCaseInputDirective } from './utils/gui/to-uppercase.directive';
import { mainInterceptor } from './utils/interceptor/main.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { HollyDaysCalendarModule } from './business/entity-setup/holly-days-calendar/holly-days-calendar.module';
import { EntitySetupModule } from './business/entity-setup/entity-setup.module';
import { DataManagerModule } from './business/data-manager/data-manager.module';
import { PrimeAllModule } from './common/prime-all/prime-all.module';

@NgModule({
	declarations: [AppComponent, UpperCaseInputDirective],
	imports: [
		BrowserModule,
		AppRoutingModule,
		KeycloakAngularModule,
		BrowserAnimationsModule,
		RouterModule,
		RouterOutlet,
		ReactiveFormsModule,
		ToastModule,
		HollyDaysCalendarModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		provideHttpClient(withInterceptors([mainInterceptor])),
		{
			provide: APP_INITIALIZER,
			useFactory: SSOInitializer,
			multi: true,
			deps: [KeycloakService],
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
